<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <KnowledgeFilter
      :search.sync="filter.searchCodeAndSubject"
      :status-filter.sync="filter.status"
      :search-tags.sync="filter.tags"
      :search-type.sync="filter.searchType"
      :type-options="typeOptions"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Knowledge Base"
        to-path="/other/knowledge/add"
        @change="fetchFilter()" />
      <b-table
        striped
        hover
        responsive
        :items="items"
        :fields="fields"
        @row-clicked="rowClick($event)"
      >
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(code)="data">
          <span class="font-weight-semibold text-primary">#{{ data.value }}</span>
        </template>
        <template #cell(subject)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(problemDetail)="data">
          <span
            class="font-weight-semibold wrap-text"
            v-html="$wrapWords(data.value, 10)"></span>
        </template>
        <template #cell(knowledgeType)="{ item }">
          <div
            v-if="item && item.knowledgeType"
            class="font-weight-semibold">
            {{
              item.knowledgeType.title
            }}
          </div>
        </template>
        <template #cell(solutionDetail)="data">
          <span
            class="font-weight-semibold wrap-text"
            v-html="$wrapWords(data.value, 10)"></span>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import TableTop from '@/components/TableTop.vue'
import KnowledgeFilter from '@/components/Others/Knowledge/KnowledgeFilter.vue'
import KnowledgeBaseProvider from '@/resources/KnowledgeBaseProvider'
import KnowledgeTypeProvider from '@/resources/KnowledgeTypeProvider'

const KnowledgeBaseService = new KnowledgeBaseProvider()
const KnowledgeTypeService = new KnowledgeTypeProvider()

export default {
  components: {
    TableTop,
    KnowledgeFilter
  },
  data () {
    return {
      filter: {
        searchCodeAndSubject: '',
        status: '',
        tags: [],
        searchType: []
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'code', label: 'Code', sortable: true },
        { key: 'subject',
          label: ' Subject',
          thStyle: {
            width: '20rem'
          } },
        { key: 'problemDetail', label: ' Problem' },
        { key: 'solutionDetail', label: ' Solution' },
        { key: 'knowledgeType', label: 'Type' },
        // { key: 'updatedAt', label: 'Updated At', sortable: true },
        // { key: 'updatedBy', label: 'Updated By' },
        { key: 'status', label: 'Status' }
      ],
      items: [
      ],
      typeOptions: []
    }
  },
  async mounted () {
    await this.getKnowledge()
    await this.getKnowledgeType()
  },
  methods: {
    async getKnowledge () {
      try {
        const data = await KnowledgeBaseService.paginate(this.pagination.page, this.pagination.limit, this.filter)
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async getKnowledgeType () {
      try {
        const { data } = await KnowledgeTypeService.paginate(1, 9999999)
        this.typeOptions = data
      } catch (error) {
        console.error(error)
      }
    },
    fetchFilter () {
      this.setPagination(1)
      this.getKnowledge()
    },
    rowClick (val) {
      this.$router.push(`/other/knowledge/${val.id}`)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getKnowledge()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
.wrap-text {
   overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
   -webkit-box-orient: vertical;
}
</style>
